<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck :title="getRes(proDeckTitle)" :cards="items" v-slot="{ card }">
        <pro-deck-card
          :title="card.title"
          :date="card.date"
          expandable
          default-closed
        >
          <template>
            <Pro-folders
              :folders="card.technicalFolder"
              :show-download-button="true"
            />
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProFolders from "@/components/PROSmart/ProFolders";

export default {
  components: { ProFolders, ProMenuLayout, ProDeckCard, ProDeck },
  name: "ViewInterestAssessor",
  data() {
    return {
      items: [],
      proDeckTitle: "CustMenu.Com.Stepper.InterestResponses",
    };
  },
  created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.$proSmart.documentUi.getInfo(this, this.tenderId).then((formData) => {
      if (formData.moduleName === "EOI") {
        this.proDeckTitle = "CustMenu.Com.Stepper.InterestResponsesEOI";
      } else if (formData.moduleName === "PQ") {
        this.proDeckTitle = "CustMenu.Com.Stepper.InterestResponsesPQ";
      }
    });
  },
  async mounted() {
    let lists = await this.$proSmart.documentUi.getFinalInterestListForAssessor(
      this,
      this.$route.params.id
    );

    this.items = lists.reduce(
      (tenderers, { name: title, submissionDate: date, ...rest }) => {
        if (date) date = new Date(date);
        return [...tenderers, { title, date, ...rest }];
      },
      []
    );
  },
};
</script>
